import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckmarkInBlue } from '../../assets/icons/check-mark-in-blue.svg'
import * as S from './Finish.styles'
import useSurveyContext from '../../store/SurveyContext'
import { useQueryParameterContext } from '../../providers/QueryParameterProvider'
import { handleRedirectToThirdPartySite } from '../../helpers/Review.helper'

const Finish = () => {
  const { t } = useTranslation()
  const { survey } = useSurveyContext()
  const queryParamsContext = useQueryParameterContext()

  return (
    <S.Container>
      <S.Title>{t('thank_you')}</S.Title>
      <S.Subtitle>{t('finish.description')}</S.Subtitle>
      <S.Frame>
        <CheckmarkInBlue />
      </S.Frame>
      {survey?.websiteUrl && (
        <S.GoToClinic
          onClick={() => handleRedirectToThirdPartySite(survey.websiteUrl || '', queryParamsContext.embedded)}
          fullWidth
        >
          {t('finish.button.goToClinic')}
        </S.GoToClinic>
      )}
    </S.Container>
  )
}

export default Finish

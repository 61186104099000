import { Button } from '@dentalux/ui-library-core/cjs'
import { Typography, Container as ContainerBase, Box } from '@mui/material'

import { styled } from '@mui/material'

export const Container = styled(ContainerBase)(({ theme }) => ({
  maxWidth: '592px',
  width: '100%',
  margin: 'auto',
  textAlign: 'center',
  padding: theme.spacing(3),
  marginTop: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(5),
}))

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography['Lato H2'],
}))

Title.defaultProps = {
  variant: 'Lato H4',
}

export const Subtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography['Lato H4'],
  marginTop: theme.spacing(2),
}))

Subtitle.defaultProps = {
  variant: 'Lato Body 1',
}

export const Frame = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(13.5),
  marginLeft: theme.spacing(4),
}))

export const GoToClinic = styled(Button)(({ theme }) => ({
  margin: 'auto',
  marginTop: theme.spacing(24),
  marginBottom: theme.spacing(6),
}))
